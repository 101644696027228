import React, { useMemo } from 'react';
import { BarDntChart } from './BarDntChart';
import { isRequesting } from '../../../utils';
import { DashboardWidget, DashboardWidgetContent, DashboardWidgetHeader } from '../widget';
import { SubscriptionFeature } from '../../../types/billing/SubscriptionFeature';
import { DashboardSkeleton } from '../DashboardSkeleton';
import { useAppSelector } from '../../../effects/useAppSelector';
import { ChartRequiredFeature } from '../../access/ChartRequiredFeature';
import { ShowFor } from '../../access';

const chartName = "DNT %: Demand Barometer";

export function BarDntWidget() {
    const dntRates = useAppSelector(s => s.dashboard.dntRates);
    const requestStateFetchDntRates = useAppSelector(s => s.dashboard.requestStateFetchDntRates);
    const filter = useAppSelector(s => s.dashboard.filter);
    const filterActive = useAppSelector(s => s.dashboard.filterActive);

    const { selectedCurrencies, selectedRatings } = filter;

    const filteredDntRates = useMemo(() => dntRates
        .sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime())
        .map(rates => ({
            ...rates,
            dntRates: rates.dntRates.filter(rate => {
                if (selectedRatings.length && !selectedRatings.some(rating => rate.rating === rating)) {
                    return false;
                }
                return !(selectedCurrencies.length && !selectedCurrencies.some(currency => rate.currency === currency));
            })
        })), [dntRates, selectedRatings, selectedCurrencies]);
    const chart = useMemo(() => <BarDntChart data={filteredDntRates} selectedCurrencies={selectedCurrencies} />,
        [filteredDntRates, selectedCurrencies]);

    return (
        <DashboardWidget title={chartName} className="dnt-rate" filterActive={filterActive}>
            <DashboardSkeleton inProgress={isRequesting(requestStateFetchDntRates)}>
                <ChartRequiredFeature
                    feature={SubscriptionFeature.getDntRates}
                    chartName={chartName}
                    blockedClassName="restricted-placeholder-dnt-percent-demand"
                >
                    <DashboardWidgetHeader>
                        <h3>{chartName}<span className="date">rolling 30-day avg</span></h3>
                    </DashboardWidgetHeader>
                    <DashboardWidgetContent
                        requestState={requestStateFetchDntRates}
                        description={
                            <ShowFor feature={SubscriptionFeature.getDntRates}>
                                                        DNT (did-not-trade) % represents the percentage of listed securities that did not trade as
                            indicated in post-BWIC color.
                            </ShowFor>
                        }
                    >
                            {chart}
                    </DashboardWidgetContent>
                </ChartRequiredFeature>
            </DashboardSkeleton>
        </DashboardWidget>
    );
}
